import React, { useState, useEffect, useContext } from "react";
import {
  CpCheckbox,
  CpInput,
  CpRadio,
  CpSelectSingle,
  CpWell,
} from "canopy-styleguide!sofe";
import { RoutingAccountImage } from "./routingAccountImage.component";
import { ClientContext } from "src/client-portal.context";
import { PaymentContext } from "../billing-context";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { wholeNumberFilter } from "../billing-helpers";
import { states } from "./create-edit-payment.helper";

export const PaymentFormACH = ({ forceSave }) => {
  const { tenant } = useContext(ClientContext);
  const { paymentDetails, actions, status } = useContext(PaymentContext);
  const { achData, makeDefault, paymentMethod, saveCard, savedPaymentMethods } =
    paymentDetails;

  const phoneOnly = useMediaQuery("--phone-only");

  const editMode = paymentMethod.type === "savedACH";

  const [routingNumActive, setRoutingNumActive] = useState(false);
  const [accountNumActive, setAccountNumActive] = useState(false);

  useEffect(() => {
    !editMode && actions.toggleMakeDefault(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'actions' and 'editMode'

  useEffect(() => {
    if (forceSave) {
      actions.toggleSaveCard(true);
    }
  }, [forceSave]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'actions'

  useEffect(() => {
    if (editMode) {
      const name = paymentMethod.account_holder_name;
      const index = name.indexOf(" ");
      const firstName = index > 0 ? name.substr(0, index) : name;
      const lastName = index > 0 ? name.substr(index + 1).trim() : "";

      actions.updateACHData({
        ...achData,
        accountType: paymentMethod.account_type.toLowerCase(),
        routingNumber: paymentMethod.routing_number,
        name,
        firstName,
        lastName,
        street: paymentMethod.billing_address.street,
        street2: paymentMethod.billing_address.street2,
        city: paymentMethod.billing_address.city,
        state: paymentMethod.billing_address.state,
        zip: paymentMethod.billing_address.zip,
        editMode,
      });
    } else if (savedPaymentMethods?.length === 0) {
      actions.toggleMakeDefault(true);
    }
    return () => {
      actions.toggleMakeDefault(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'achData', 'actions', 'editMode', 'paymentMethod.account_holder_name', 'paymentMethod.account_type', 'paymentMethod.billing_address.city', 'paymentMethod.billing_address.state', 'paymentMethod.billing_address.street', 'paymentMethod.billing_address.street2', 'paymentMethod.billing_address.zip', 'paymentMethod.routing_number', and 'savedPaymentMethods?.length'

  const handleAccountTypeChanged = (accountType) => {
    actions.updateACHData({ ...achData, accountType });
  };

  const handleRoutingNumberChanged = (value) => {
    const routingNumber = wholeNumberFilter(value, true);
    actions.updateACHData({ ...achData, routingNumber });
  };

  const handleAccountNumberChanged = (value) => {
    const accountNumber = wholeNumberFilter(value, true);
    actions.updateACHData({ ...achData, accountNumber });
  };

  const handleConfirmAccountNumberChanged = (value) => {
    const confirmAccountNumber = wholeNumberFilter(value, true);
    actions.updateACHData({ ...achData, confirmAccountNumber });
  };

  const handleNameChanged = (value) => {
    const name = value.trimLeft();
    const index = name.indexOf(" ");
    const firstName = index > 0 ? name.substr(0, index) : name;
    const lastName = index > 0 ? name.substr(index + 1).trim() : "";
    actions.updateACHData({ ...achData, name, firstName, lastName });
  };

  const handleStreetChanged = (value) => {
    const street = value.trimLeft();
    actions.updateACHData({ ...achData, street });
  };

  const handleStreet2Changed = (value) => {
    const street2 = value.trimLeft();
    actions.updateACHData({ ...achData, street2 });
  };

  const handleCityChanged = (value) => {
    const city = value.trimLeft();
    actions.updateACHData({ ...achData, city });
  };

  const handleStateChanged = (value) => {
    const state = value.key;
    actions.updateACHData({ ...achData, state });
  };

  const handleStateAutofill = (event) => {
    const value = event.target.value;
    if (!value || !states.map((s) => s.value).includes(value)) {
      actions.updateACHData({ ...achData, state: "" });
    } else {
      actions.updateACHData({ ...achData, state: event.target.value });
    }
  };

  const handleZipChanged = (value) => {
    const zip = wholeNumberFilter(value, true);
    actions.updateACHData({ ...achData, zip });
  };

  const handleNicknameChanged = (value) => {
    const nickname = value.trimLeft();
    actions.updateNickname(nickname);
  };

  return (
    <div>
      <div className="flexwrap">
        <div
          className="cp-flex-spread-center"
          style={{ flex: phoneOnly ? "0 0 100%" : 1 }}
        >
          <CpRadio
            name="accountType"
            value={achData.accountType}
            onChange={handleAccountTypeChanged}
          >
            <CpRadio.Item id="checking">Checking</CpRadio.Item>
            <CpRadio.Item id="savings">Savings</CpRadio.Item>
          </CpRadio>
          <RoutingAccountImage
            routingNumActive={routingNumActive}
            accountNumActive={accountNumActive}
          />
        </div>
        <div style={{ flex: phoneOnly ? "0 0 100%" : 1 }}>
          <CpInput
            id="routingNumber"
            label="Routing number"
            required
            maxLength={9}
            inputmode="numeric"
            value={achData.routingNumber}
            onFocus={(e) => {
              actions.clearValidationError(e);
              setRoutingNumActive(true);
            }}
            onChange={handleRoutingNumberChanged}
            onBlur={(e) => {
              actions.validate(e);
              setRoutingNumActive(false);
            }}
            error={
              status.invalidFields.includes("routingNumber")
                ? achData.routingNumber === ""
                  ? "Routing number is required."
                  : "Invalid routing number."
                : false
            }
          />
        </div>
      </div>
      <div className="cp-mt-24 flexwrap">
        <div style={{ flex: phoneOnly ? "0 0 100%" : 1 }}>
          <CpInput
            id="accountNumber"
            label="Account number"
            required
            maxLength={17}
            inputmode="numeric"
            value={achData.accountNumber}
            onFocus={(e) => {
              actions.clearValidationError(e);
              setAccountNumActive(true);
            }}
            onChange={handleAccountNumberChanged}
            onBlur={(e) => {
              actions.validate(e);
              setAccountNumActive(false);
            }}
            error={
              status.invalidFields.includes("accountNumber")
                ? achData.accountNumber === ""
                  ? "Account number is required."
                  : "Invalid account number."
                : false
            }
          />
        </div>
        <div style={{ flex: phoneOnly ? "0 0 100%" : 1 }}>
          <CpInput
            id="confirmAccountNumber"
            label="Confirm account number"
            required
            maxLength={17}
            inputmode="numeric"
            value={achData.confirmAccountNumber}
            onFocus={(e) => {
              actions.clearValidationError(e);
              setAccountNumActive(true);
            }}
            onChange={handleConfirmAccountNumberChanged}
            onBlur={(e) => {
              actions.validate(e);
              setAccountNumActive(false);
            }}
            error={
              status.invalidFields.includes("confirmAccountNumber")
                ? achData.accountNumber === ""
                  ? "Account number is required."
                  : "Account numbers do not match."
                : false
            }
          />
        </div>
      </div>
      <div className="cp-mt-24 flexwrap">
        <div style={{ flex: 1 }}>
          <CpInput
            id="name"
            label="Name on account"
            required
            maxLength={22}
            value={achData.name}
            onFocus={actions.clearValidationError}
            onChange={handleNameChanged}
            onBlur={actions.validate}
            error={
              status.invalidFields.includes("name")
                ? achData.firstName === "" && achData.lastName === ""
                  ? "Name is required."
                  : "First and Last name are required."
                : false
            }
          />
        </div>
      </div>
      <div className="cp-mt-24 flexwrap">
        <div style={{ flex: phoneOnly ? "0 0 100%" : 3 }}>
          <CpInput
            id="street"
            label="Street"
            required
            maxLength={50}
            value={achData.street}
            onFocus={actions.clearValidationError}
            onChange={handleStreetChanged}
            onBlur={actions.validate}
            error={
              status.invalidFields.includes("street")
                ? "Street is required."
                : false
            }
          />
        </div>
        <div style={{ flex: phoneOnly ? "0 0 100%" : 2 }}>
          <CpInput
            id="street2"
            label="Apt./Suite"
            maxLength={30}
            value={achData.street2}
            onChange={handleStreet2Changed}
          />
        </div>
      </div>
      <div className="cp-mt-24 flexwrap">
        <div style={{ flex: phoneOnly ? "0 0 100%" : 3 }}>
          <CpInput
            id="city"
            label="City"
            required
            maxLength={40}
            value={achData.city}
            onFocus={actions.clearValidationError}
            onChange={handleCityChanged}
            onBlur={actions.validate}
            error={
              status.invalidFields.includes("city")
                ? "City is required."
                : false
            }
          />
        </div>
        <div
          style={{ flex: phoneOnly ? "0 0 100%" : 1 }}
          className={
            status.invalidFields.includes("state") ? "cps-has-error" : ""
          }
        >
          <label htmlFor="state" className="cp-label">
            State
            <span className="cp-color-app-primary"> *</span>
          </label>
          <div style={{ width: "0", height: "0", overflow: "hidden" }}>
            <input
              type="text"
              tabindex="-1"
              name="state"
              onChange={handleStateAutofill}
              value={achData.state}
            />
          </div>
          <div>
            <CpSelectSingle
              id="state"
              triggerIsBlock
              fixedContent
              contentWidth="block"
              data={states}
              value={{ key: achData.state, value: achData.state }}
              onOpen={() =>
                actions.clearValidationError({ target: { id: "state" } })
              }
              onBlur={() =>
                actions.validate({
                  target: { id: "state", value: achData.state },
                })
              }
              onChange={handleStateChanged}
              transformData={(item) => ({ id: item.key, name: item.value })}
            />
          </div>
          <span className="cps-error-block">State is required.</span>
        </div>
        <div style={{ flex: phoneOnly ? "0 0 100%" : 1.5 }}>
          <CpInput
            id="zip"
            label="Zip code"
            required
            maxLength={5}
            value={achData.zip}
            onFocus={actions.clearValidationError}
            onChange={handleZipChanged}
            onBlur={actions.validate}
            error={
              status.invalidFields.includes("zip")
                ? achData.zip === ""
                  ? "Zip is required."
                  : "Invalid zip code."
                : false
            }
          />
        </div>
      </div>
      <div className="cp-mt-24 flexwrap" style={{ alignItems: "center" }}>
        {!forceSave && (
          <div
            style={{ flex: phoneOnly ? "0 0 100%" : 1, marginBottom: "0px" }}
          >
            <CpCheckbox
              onChange={(isChecked) => actions.toggleSaveCard(isChecked)}
              checked={saveCard}
            >
              Save bank account for future payments
            </CpCheckbox>
          </div>
        )}
        {savedPaymentMethods?.length > 0 && saveCard && (
          <div style={{ flex: phoneOnly ? "0 0 100%" : 1 }}>
            <CpCheckbox
              onChange={(isChecked) => actions.toggleMakeDefault(isChecked)}
              checked={makeDefault}
            >
              Make default payment method
            </CpCheckbox>
          </div>
        )}
      </div>
      {saveCard && (
        <>
          <div className="cp-mt-24" style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <CpInput
                id="nickname"
                label="Account nickname (optional)"
                maxLength={30}
                value={paymentMethod.nickname}
                onChange={handleNicknameChanged}
              />
            </div>
          </div>
          <div className="cp-mt-24">
            <CpWell level={3} className="cp-p-16">
              <CpCheckbox
                onChange={(isChecked) => actions.authorizeUse(isChecked)}
                checked={paymentMethod.authorizedUse}
              >
                <div className="cp-ml-12">
                  I authorize {tenant.company_name} to charge my bank account
                  above for agreed-upon transactions. I understand that my
                  information will be saved to file for future transactions on
                  my account.
                </div>
              </CpCheckbox>
            </CpWell>
          </div>
        </>
      )}
    </div>
  );
};
