import React, { useContext, useEffect } from "react";
import { DateTime } from "luxon";
import { useCss, k } from "kremling";
import { navigate } from "@reach/router";
import { CpButton, CpLoader } from "canopy-styleguide!sofe";
import PageTitle from "@common/page-title.component";
import { TodoDetailsCard } from "../todo-details-card.component";
import { TodoDetailsHeader } from "../todo-details-header.component";
import { CommentSection } from "../client-request/comments/comment-section.component";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { ClientContext } from "../../client-portal.context";
import {
  useGetQuestionnaire,
  useGetClientRequest,
} from "./use-get-questionnaire.hook";
import { QUESTIONNAIRE_TYPES } from "./questionnaire-types.constant";
import { useRequestCommentAttachments } from "src/todo/client-request/queries/use-request-comment-attachments.hook";

export const QuestionnaireWrapper = ({
  location,
  clientId,
  clientRequestId,
  questionnaireId,
  documentChecklistId,
}) => {
  const cameFrom = location.state?.cameFrom;
  const { selectedClient, setShowNav } = useContext(ClientContext);
  const phoneOnly = useMediaQuery("--phone-only");
  const requestType = documentChecklistId
    ? QUESTIONNAIRE_TYPES.DOCUMENT_CHECKLIST
    : QUESTIONNAIRE_TYPES.QUESTIONNAIRE;

  const {
    data: questionnaire,
    isLoading,
    isError,
  } = useGetQuestionnaire({
    id: documentChecklistId || questionnaireId,
    api: requestType.api,
  });

  useEffect(() => {
    setShowNav(!phoneOnly);

    return () => {
      setShowNav(true);
    };
  }, [phoneOnly]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'setShowNav'

  const {
    data: clientRequest,
    isLoading: clientRequestIsLoading,
    isError: clientRequestIsError,
  } = useGetClientRequest(clientRequestId);

  const commentIds = clientRequest?.relationships?.comments?.map(
    (comment) => comment.id
  );
  const { filesByComment } = useRequestCommentAttachments({
    clientRequestId,
    commentIds,
  });

  const styles = useCss(css);
  const dueDate = clientRequest?.due_date
    ? DateTime.fromISO(clientRequest?.due_date).toLocaleString(
        DateTime.DATE_SHORT
      )
    : "";
  const goBackPath = `/m/clients/${selectedClient?.id}${
    cameFrom === "todo" ? "/todo" : ""
  }`;

  if (isLoading || clientRequestIsLoading) {
    return (
      <div className="cp-mt-40">
        <CpLoader />
      </div>
    );
  }

  if (isError || clientRequestIsError) {
    return (
      <div {...styles} className="cp-mt-40 page-content">
        <div className="body-content-wrapper">
          <div>
            There was an error loading the
            {requestType.sentence}.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div {...styles}>
      <div className="new-header">
        <CpButton
          btnType="icon-dark"
          onClick={() => navigate(goBackPath)}
          icon="caret-large-left"
          aria-label="Back"
        />
        <div className="new-header-content">{questionnaire?.title}</div>
      </div>
      <div className="page-content">
        {!phoneOnly && (
          <PageTitle
            left={
              <CpButton
                btnType="tertiary"
                onClick={() => navigate(goBackPath)}
                icon="caret-large-left"
                aria-label="Back"
              >
                Back
              </CpButton>
            }
            center={<span className="title-text">{questionnaire?.title}</span>}
          />
        )}
        <TodoDetailsCard
          headerContent={
            <TodoDetailsHeader
              title={requestType.title}
              dueDate={dueDate}
              iconName={requestType.icon}
            />
          }
          bodyContent={
            <div {...styles}>
              <CommentSection
                message={questionnaire.description}
                clientRequest={clientRequest}
                clientId={clientRequest.client_id}
                refetchTask={() => {}}
                firstSentBy={clientRequest.first_sent_by_user_full_name}
                firstSentAt={clientRequest.first_sent_at}
                filesByComment={filesByComment}
              />
            </div>
          }
          footerContent={
            <CpButton
              onClick={() =>
                navigate(
                  `/m/clients/${clientId}/todo/client-request/${clientRequestId}/${
                    requestType.url
                  }/${questionnaireId || documentChecklistId}`,
                  {
                    state: { cameFrom },
                  }
                )
              }
              block
            >
              {requestType.button}
            </CpButton>
          }
        />
      </div>
    </div>
  );
};

const css = k`
  .body-content-wrapper {
    padding: 1.6rem;
  }
  
  .new-header {
    height: 6.4rem;
    background-color: var(--cp-color-app-primary);
    color: var(--cp-color-app-primary-dark-text);
    padding: 0 1.6rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .new-header-content {
    position: absolute;
    text-align: center;
    left: 8rem;
    right: 8rem;
    font-size: 1.4rem;
    font-weight: 600;
  }

  @media screen and (min-width: 600px) {
    .new-header {
      display: none;
    }
  }

`;
