import React, { useEffect, useState, useRef } from "react";
import css from "./navigation.krem.css";
import { useCss, a } from "kremling";
import { Link } from "@reach/router";
import HomeIcon from "./icons/home-icon.component.js";
import TodoIcon from "./icons/todo-icon.component.js";
import FilesIcon from "./icons/files-icon.component.js";
import BillingIcon from "./icons/billing-icon.component.js";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { useWhiteLabelSettings } from "../white-label/custom-hooks.js";
import CompanyInfo from "./company-info.component";
import MobileHeader from "./mobile-header/mobile-header.component";
import AccountMenu from "./account-menu/account-menu.component";
import useTodoCount from "./use-todo-count";
import { CpButton } from "canopy-styleguide!sofe";
import { track } from "src/common/analytics.helper.js";

export default function Navigation(props) {
  const [showMenu, setMenu] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    window.addEventListener("click", checkCloseMenu, { capture: true });
    return () =>
      window.removeEventListener("click", checkCloseMenu, { capture: true });
  }, [showMenu]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'checkCloseMenu'

  const { selectedClient } = props;
  const todoCount = useTodoCount();

  const phoneOnly = useMediaQuery("--phone-only");
  useEffect(() => {
    if (phoneOnly) setMenu(false);
  }, [phoneOnly, props.location]);

  const whiteLabelSettings = useWhiteLabelSettings();
  const scope = useCss(css);
  const showNav = () => showMenu || !phoneOnly;
  const appointmentSchedulerLink =
    whiteLabelSettings?.appointment_scheduler_link;

  function checkCloseMenu(e) {
    if (showMenu && !menuRef.current.contains(e.target)) {
      setMenu(false);
    }
  }

  useEffect(() => {
    // disable scroll of body if phone nav sidebar open
    if (!phoneOnly) return;
    document.getElementsByTagName("body")[0].style.overflow = showMenu
      ? "hidden"
      : null;
    return () =>
      (document.getElementsByTagName("body")[0].style.overflow = null);
  }, [showMenu, phoneOnly]);

  return (
    <>
      {phoneOnly && (
        <>
          <MobileHeader
            setMenu={setMenu}
            selectedClient={selectedClient}
            whiteLabelSettings={whiteLabelSettings}
          />
          {/* this functions as a sort of backdrop which restricts triggering buttons/inputs behind the slide out menu */}
          {showMenu && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 1,
              }}
            />
          )}
        </>
      )}
      <nav
        {...scope}
        style={{ left: showNav() ? 0 : -500 }}
        className={`navigation cps-card__height-3 ${
          selectedClient ? "" : "disabled"
        }`}
        ref={menuRef}
      >
        <div className="scrollable-section">
          <div>
            <Link to={"./"} className="logo-container">
              {whiteLabelSettings.logo_url ? (
                <img
                  className="practitioner-logo"
                  src={whiteLabelSettings.logo_url}
                />
              ) : (
                <div
                  style={{
                    color:
                      whiteLabelSettings.color_hex ||
                      "var(--cp-color-app-primary)",
                  }}
                  className="cps-subheader company-name"
                >
                  {whiteLabelSettings.company_name}
                </div>
              )}
            </Link>
            <Divider />
            <Link to="./" getProps={isActive}>
              <div className="bar" />
              <HomeIcon />
              <div>Home</div>
            </Link>
            <Divider />
            <Link to="todo" getProps={isActive}>
              <div className="bar" />
              <TodoIcon />
              {todoCount?.length && (
                <div className="nav-item-dot">{todoCount}</div>
              )}
              <span>To-do</span>
            </Link>
            <Divider />
            <Link to="files" getProps={isActive}>
              <div className="bar" />
              <FilesIcon />
              <div>Files</div>
            </Link>
            <Divider />
            {props.enableBilling && (
              <Link to="billing" getProps={isActive}>
                <div className="bar" />
                <BillingIcon />
                <div>Billing</div>
              </Link>
            )}
            {!!appointmentSchedulerLink?.enabled && (
              <div className="scheduler-container">
                <CpButton
                  aria-label="Schedule an appointment"
                  btnType="secondary"
                  icon="misc-calendar"
                  className="cp-ph-16"
                  onClick={() => {
                    track("third-party-link_clicked", {
                      linkClicked: "Scheduler",
                    });
                    window.open(
                      appointmentSchedulerLink.link,
                      "_blank",
                      "noopener=yes,noreferrer=yes"
                    );
                  }}
                >
                  Schedule an appointment
                </CpButton>
              </div>
            )}
          </div>
          <div>
            <CompanyInfo tenant={props.tenant} />
            <AccountMenu
              customLinks={whiteLabelSettings?.custom_links?.filter(
                (link) => link.enabled
              )}
              companyWebsite={whiteLabelSettings?.company_website_link}
              enableBilling={props.enableBilling}
              showDisplayName={whiteLabelSettings.show_display_name}
            />
          </div>
        </div>
      </nav>
    </>
  );
}

function isActive(linkData) {
  // `/m/client/6480215` is home href form and it needs to be marked with `isCurrent` otherwise it thinks it's always active
  const isHome = linkData.href.match(/\//g).length === 3;
  return {
    className: a("navigationLink").m(
      "active",
      isHome ? linkData.isCurrent : linkData.isPartiallyCurrent
    ),
  };
}

function Divider() {
  return (
    <div className="cps-padding-right-16 cps-padding-left-16">
      <hr className="cps-margin-0" />
    </div>
  );
}
