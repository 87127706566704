import React, { useContext, useState, useEffect, useMemo } from "react";
import UserMessageCard from "./user-message-card.component";
import { ClientContext } from "../client-portal.context";
import canopyUrls from "canopy-urls!sofe";
import { CpButton } from "canopy-styleguide!sofe";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { useSwitchUser } from "login-ui!sofe";
import CpRadio from "./cp-radio.component";
import { useCss } from "kremling";
import css from "./client-not-found.krem.css";
import { getResponsivePath } from "@utils/routing";

export function ClientNotFound() {
  const { entities, clients } = useContext(ClientContext);
  const [user] = useWithUserAndTenant();
  const [selectedId, setSelectedId] = useState();
  const switchUser = useSwitchUser();
  const logoutUrl = `${canopyUrls.getWebUrl()}${getResponsivePath()}logout?redirect_url=${canopyUrls.getWebUrl()}`;

  const entitiesExcludingActive = useMemo(
    () => entities?.filter((entity) => entity.id !== user?.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entities]
    // lint-td: has missing dependencies: 'user?.id'
  );
  const altEntitiesExist = entitiesExcludingActive?.length > 0;

  useEffect(() => {
    if (altEntitiesExist) {
      setSelectedId(entitiesExcludingActive[0].id);
    }
  }, [altEntitiesExist, entitiesExcludingActive]);

  return (
    <div {...useCss(css)}>
      {clients?.length ? (
        <UserMessageCard
          img="es_access"
          text="That client does not exist or you do not have access to it"
        />
      ) : (
        <UserMessageCard
          img="es_access"
          text={
            altEntitiesExist
              ? "You don't have access to this client portal, please select another portal"
              : "Sorry, you don't have access to this client portal"
          }
          subText="If you have any questions, feel free to reach out to your accountant."
          cta={
            !altEntitiesExist && (
              <CpButton
                btnType="primary"
                onClick={() => (window.location = logoutUrl)}
              >
                Logout
              </CpButton>
            )
          }
          noLeftNav={true}
        >
          {altEntitiesExist && (
            <>
              <CpRadio onChange={setSelectedId} value={selectedId}>
                {entitiesExcludingActive.map((e) => (
                  <CpRadio.Item
                    key={e.id}
                    label={e.company_name}
                    value={e.id}
                  />
                ))}
              </CpRadio>
              <div className="buttons">
                <CpButton
                  btnType="primary"
                  onClick={() =>
                    switchUser(entities.find((ent) => ent.id === selectedId))
                  }
                >
                  Switch to this portal
                </CpButton>
                <CpButton
                  btnType="flat"
                  anchor
                  href={logoutUrl}
                  className="cp-mt-4"
                >
                  Back to login page
                </CpButton>
              </div>
            </>
          )}
        </UserMessageCard>
      )}
    </div>
  );
}
