import React, { useState, useEffect, useContext } from "react";
import { get, findKey } from "lodash";
import {
  CpButton,
  CpCheckbox,
  CpIcon,
  CpInput,
  CpSelectSingle,
  CpWell,
} from "canopy-styleguide!sofe";
import { CvvModal } from "./cvv-modal.component";
import { ClientContext } from "src/client-portal.context";
import { BillingContext, PaymentContext } from "../billing-context";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { usePaysafe } from "./payment.hooks";
import { wholeNumberFilter } from "../billing-helpers";
import {
  cardIconNames,
  paysafeCardTypes,
  states,
} from "./create-edit-payment.helper";

export const PaymentFormCC = ({ forceSave }) => {
  const { tenant } = useContext(ClientContext);
  const { amexEnabled } = useContext(BillingContext);
  const { paymentDetails, actions, status } = useContext(PaymentContext);
  const {
    creditCardData,
    makeDefault,
    paymentMethod,
    saveCard,
    savedPaymentMethods,
  } = paymentDetails;
  const { cardType, errors } = usePaysafe(
    actions.updatePaysafeInstance,
    "cp",
    actions.forceUpdate
  );
  const [showCvvModal, setShowCvvModal] = useState(false);

  const phoneOnly = useMediaQuery("--phone-only");

  const editMode = paymentMethod.type === "savedCreditCard";

  useEffect(() => {
    !editMode && actions.toggleMakeDefault(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'actions' and 'editMode'

  useEffect(() => {
    actions.updateCCData({
      ...creditCardData,
      cardType,
      validCardType: cardType ? status.validCardTypes.includes(cardType) : true,
    });
  }, [cardType]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'actions', 'creditCardData', and 'status.validCardTypes'

  useEffect(() => {
    if (forceSave) {
      actions.toggleSaveCard(true);
    }
  }, [forceSave]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'actions'

  useEffect(() => {
    if (editMode) {
      actions.updateCCData({
        ...creditCardData,
        name: paymentMethod.card_holder_name,
        street: paymentMethod.billing_address.street,
        street2: paymentMethod.billing_address.street2,
        city: paymentMethod.billing_address.city,
        state: paymentMethod.billing_address.state,
        zip: paymentMethod.billing_address.zip,
        editMode,
      });
    } else if (savedPaymentMethods?.length === 0) {
      actions.toggleMakeDefault(true);
    }
    return () => {
      actions.toggleMakeDefault(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'actions', 'creditCardData', 'editMode', 'paymentMethod.billing_address.city', 'paymentMethod.billing_address.state', 'paymentMethod.billing_address.street', 'paymentMethod.billing_address.street2', 'paymentMethod.billing_address.zip', 'paymentMethod.card_holder_name', and 'savedPaymentMethods?.length'

  const handleNameChanged = (value) => {
    const name = value.trimLeft();
    actions.updateCCData({ ...creditCardData, name });
  };

  const handleStreetChanged = (value) => {
    const street = value.trimLeft();
    actions.updateCCData({ ...creditCardData, street });
  };

  const handleStreet2Changed = (value) => {
    const street2 = value.trimLeft();
    actions.updateCCData({ ...creditCardData, street2 });
  };

  const handleCityChanged = (value) => {
    const city = value.trimLeft();
    actions.updateCCData({ ...creditCardData, city });
  };

  const handleStateChanged = (value) => {
    const state = value.key;
    actions.updateCCData({ ...creditCardData, state });
  };

  const handleStateAutofill = (event) => {
    const value = event.target.value;
    if (!value || !states.map((s) => s.value).includes(value)) {
      actions.updateCCData({ ...creditCardData, state: "" });
    } else {
      actions.updateCCData({ ...creditCardData, state: event.target.value });
    }
  };

  const handleZipChanged = (value) => {
    const zip = wholeNumberFilter(value, true);
    actions.updateCCData({ ...creditCardData, zip });
  };

  const handleNicknameChanged = (value) => {
    const nickname = value.trimLeft();
    actions.updateNickname(nickname);
  };

  return (
    <div>
      <div
        className="flexwrap cp-color-app-inactive-text"
        style={{ alignItems: "center" }}
      >
        <div
          className=""
          style={{
            flex: phoneOnly ? "0 0 100%" : 0,
            fontStyle: "italic",
            whiteSpace: "nowrap",
          }}
        >
          Credit/Debit Accepted
        </div>
        <div className="cp-flex" style={{ flex: phoneOnly ? "0 0 0100%" : 0 }}>
          <CpIcon name="billing-visa" />
          <CpIcon name="billing-mastercard" />
          <CpIcon name="billing-discover" />
          {amexEnabled && <CpIcon name="billing-amex" />}
        </div>
      </div>
      <div className="cp-mt-24 flexwrap">
        <div style={{ flex: phoneOnly ? "0 0 100%" : 2 }}>
          <div
            className={
              errors.cardNumberInvalid || !creditCardData.validCardType
                ? "cps-has-error"
                : ""
            }
          >
            <label htmlFor="cp-card-number" className="cp-label">
              Card number
              <span className="cp-color-app-primary"> *</span>
            </label>
            <div className="cp-flex-center cp-input-container">
              <div id="cp-card-number" className="cps-form-control cp-input" />
              <div>
                <CpIcon
                  name={get(
                    cardIconNames,
                    findKey(paysafeCardTypes, (type) => type === cardType),
                    "billing-credit-card"
                  )}
                  style={{ width: "4.8rem" }}
                />
              </div>
            </div>
            <span className="cps-error-block">
              {!creditCardData.validCardType
                ? "Card type is not supported."
                : "Invalid credit card number."}
            </span>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div className={errors.expiryDateInvalid ? "cps-has-error" : ""}>
            <label htmlFor="cp-expiration-date" className="cp-label">
              Expiration date
              <span className="cp-color-app-primary"> *</span>
            </label>
            <div className="cp-input-container">
              <div
                id="cp-expiration-date"
                className="cps-form-control cp-input"
              />
            </div>
            <span className="cps-error-block">Invalid expiration date.</span>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div className={errors.cvvInvalid ? "cps-has-error" : ""}>
            <label htmlFor="cp-card-number" className="cp-label">
              CVV
              <span className="cp-color-app-primary"> *</span>
            </label>
            <div className="cp-flex-center cp-input-container">
              <div id="cp-cvv" className="cps-form-control cp-input" />
              <div className="cvv-information">
                <CpButton
                  aria-label="CVV"
                  btnType="icon"
                  icon="information-circle-open-large"
                  onClick={() => setShowCvvModal(true)}
                />
              </div>
            </div>
            <span className="cps-error-block">Invalid CVV.</span>
          </div>
        </div>
      </div>
      <div className="cp-mt-24 flexwrap">
        <div style={{ flex: 1 }}>
          <CpInput
            id="name"
            label="Name on card"
            required
            formnovalidate
            maxLength={30}
            value={creditCardData.name}
            onFocus={actions.clearValidationError}
            onChange={handleNameChanged}
            onBlur={actions.validate}
            error={
              status.invalidFields.includes("name")
                ? "Name is required."
                : false
            }
          />
        </div>
      </div>
      <div className="cp-mt-24 flexwrap">
        <div style={{ flex: phoneOnly ? "0 0 100%" : 3 }}>
          <CpInput
            id="street"
            label="Street"
            required
            maxLength={50}
            value={creditCardData.street}
            onFocus={actions.clearValidationError}
            onChange={handleStreetChanged}
            onBlur={actions.validate}
            error={
              status.invalidFields.includes("street")
                ? "Street is required."
                : false
            }
          />
        </div>
        <div style={{ flex: phoneOnly ? "0 0 100%" : 2 }}>
          <CpInput
            id="street2"
            label="Apt./Suite"
            maxLength={30}
            value={creditCardData.street2}
            onChange={handleStreet2Changed}
          />
        </div>
      </div>
      <div className="cp-mt-24 flexwrap">
        <div style={{ flex: phoneOnly ? "0 0 100%" : 3 }}>
          <CpInput
            id="city"
            label="City"
            required
            maxLength={40}
            value={creditCardData.city}
            onFocus={actions.clearValidationError}
            onChange={handleCityChanged}
            onBlur={actions.validate}
            error={
              status.invalidFields.includes("city")
                ? "City is required."
                : false
            }
          />
        </div>
        <div
          style={{ flex: phoneOnly ? "0 0 100%" : 1 }}
          className={
            status.invalidFields.includes("state") ? "cps-has-error" : ""
          }
        >
          <label htmlFor="state" className="cp-label">
            State
            <span className="cp-color-app-primary"> *</span>
          </label>
          <div style={{ width: "0", height: "0", overflow: "hidden" }}>
            <input
              type="text"
              tabindex="-1"
              name="state"
              onChange={handleStateAutofill}
              value={creditCardData.state}
            />
          </div>
          <div>
            <CpSelectSingle
              id="state"
              triggerIsBlock
              fixedContent
              contentWidth="block"
              data={states}
              value={{ key: creditCardData.state, value: creditCardData.state }}
              onOpen={() =>
                actions.clearValidationError({ target: { id: "state" } })
              }
              onBlur={() =>
                actions.validate({
                  target: { id: "state", value: creditCardData.state },
                })
              }
              onChange={handleStateChanged}
              transformData={(item) => ({ id: item.key, name: item.value })}
            />
          </div>
          <span className="cps-error-block">State is required.</span>
        </div>
        <div style={{ flex: phoneOnly ? "0 0 100%" : 1.5 }}>
          <CpInput
            id="zip"
            label="Zip code"
            required
            maxLength={5}
            value={creditCardData.zip}
            onFocus={actions.clearValidationError}
            onChange={handleZipChanged}
            onBlur={actions.validate}
            error={
              status.invalidFields.includes("zip")
                ? creditCardData.zip === ""
                  ? "Zip is required."
                  : "Invalid zip code."
                : false
            }
          />
        </div>
      </div>
      <div className="cp-mt-24 flexwrap" style={{ alignItems: "center" }}>
        {!forceSave && (
          <div
            style={{ flex: phoneOnly ? "0 0 100%" : 1, marginBottom: "0px" }}
          >
            <CpCheckbox
              onChange={(isChecked) => actions.toggleSaveCard(isChecked)}
              checked={saveCard}
            >
              Save card for future payments
            </CpCheckbox>
          </div>
        )}
        {savedPaymentMethods?.length > 0 && saveCard && (
          <div style={{ flex: phoneOnly ? "0 0 100%" : 1 }}>
            <CpCheckbox
              onChange={(isChecked) => actions.toggleMakeDefault(isChecked)}
              checked={makeDefault}
            >
              Make default payment method
            </CpCheckbox>
          </div>
        )}
      </div>
      {saveCard && (
        <>
          <div className="cp-mt-24 flexwrap">
            <div style={{ flex: 1 }}>
              <CpInput
                id="nickname"
                label="Account nickname (optional)"
                maxLength={30}
                value={paymentMethod.nickname}
                onChange={handleNicknameChanged}
              />
            </div>
          </div>
          <div className="cp-mt-24">
            <CpWell level={3} className="cp-p-16">
              <CpCheckbox
                onChange={(isChecked) => actions.authorizeUse(isChecked)}
                checked={paymentMethod.authorizedUse}
              >
                <div className="cp-ml-12">
                  I authorize {tenant.company_name} to charge my credit card
                  above for agreed-upon transactions. I understand that my
                  information will be saved to file for future transactions on
                  my account.
                </div>
              </CpCheckbox>
            </CpWell>
          </div>
        </>
      )}
      <CvvModal
        show={showCvvModal}
        onClose={() => setShowCvvModal(false)}
        amexEnabled={amexEnabled}
      />
    </div>
  );
};
