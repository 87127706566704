import React from "react";
import { useCss, k, a } from "kremling";

import { useMediaQuery } from "@hooks/use-media-query.hook";

export default function PageTitle(props) {
  const { left, right, center, children, override } = props;
  const scope = useCss(css);
  const phoneOnly = useMediaQuery("--phone-only");
  return (
    <>
      <div
        {...scope}
        className={a("page-title").m("page-title-phone", phoneOnly)}
      >
        <div className="spacing-div" />
        {(!phoneOnly || override) && (
          <div className="title-wrapper">
            <div className="left">{left}</div>
            <div className="center">{center}</div>
            <div className="right">{right}</div>
          </div>
        )}
        {children}
      </div>
    </>
  );
}

const css = k`
  .page-title {
    position: sticky;
    top: 0;
    padding: 8px 24px;
    z-index: 98;
    background-color: var(--cps-color-blue-smoke);
  }

  .page-title-phone {
    position: static;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.2rem;
    white-space: nowrap;
  }

  .title-text {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 700;
  }

  .left {
    min-width: 24px;
    flex: 1;
  }

  .right {
    min-width: 24px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .center {
    flex: 2;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .center > span {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media #{$--phone-only} {
    .spacing-div {
      height: 8px;
    }
  }

  @media #{$--tablet-portrait-up} {
    .spacing-div {
      height: 48px;
    }
    .left > a,
    .right > a {
      color: var(--cp-color-app-primary-text);
      text-decoration: none;
    }
    .page-title {
      background-color: var(--cps-color-blue-smoke);
    }
  }
`;
