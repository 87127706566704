import React from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

export const ConfirmDeleteModal = ({
  show,
  paymentType,
  canDelete,
  onCancel,
  onConfirm,
  loading,
}) => (
  <CpModal show={show} onClose={onCancel}>
    <CpModal.Header
      title={
        canDelete
          ? `Delete ${paymentType === "card" ? "Card" : "Bank Account"}`
          : "Deleting Unavailable"
      }
    />
    <CpModal.Body>
      <div className="cp-p-8">
        {canDelete
          ? "Deleting a payment method is permanent and cannot be undone"
          : `This ${
              paymentType === "card" ? "credit/debit" : "bank account"
            } cannot be deleted while you have payments pending.`}
      </div>
    </CpModal.Body>
    <CpModal.Footer>
      <CpButton btnType="secondary" className="cp-mr-8" onClick={onCancel}>
        Back
      </CpButton>
      {canDelete && (
        <CpButton btnType="primary" onClick={onConfirm} showLoader={loading}>
          Delete {paymentType === "card" ? "Card" : "Bank Account"}
        </CpButton>
      )}
    </CpModal.Footer>
  </CpModal>
);
