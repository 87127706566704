import React, {
  lazy,
  Suspense,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { isEqual } from "lodash";
import { useCss } from "kremling";
import { CpButton, CpTimezone, CpInput, CpWell } from "canopy-styleguide!sofe";
import auth from "cp-client-auth!sofe";
import css from "./settings.krem.css";
import PasswordModal from "./password-modal.component";
import { patchUser } from "./settings.resource";
import { handleError } from "src/handle-error";
import useUserHasAPendingEmailChange from "./use-pending-email-change";
import { successToast } from "toast-service!sofe";

const getValues = (user) => {
  const phone_number = user.phone_number?.split("x")?.[0];
  return {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone_number: phone_number ? `${phone_number}` : "",
    ext: user.phone_number?.split("x")?.[1],
    time_zone: user.time_zone,
  };
};

export default function Settings(props) {
  const scope = useCss(css);
  const [passModalVisible, setPassModalVisible] = useState();
  const [save, setSave] = useState();
  const { user } = props;
  const validWithCurrentUser = (attr) => values[attr];
  const [values, setValues] = useState(getValues(user));
  const original = useRef();

  useEffect(() => {
    const notFirstRender = original.current;
    original.current = getValues(user);
    if (notFirstRender) setValues(original.current);
  }, [user]);

  const TwoStepVerification = useMemo(
    () =>
      lazy(() =>
        SystemJS.import("global-settings!sofe").then((globalSettings) => {
          return globalSettings.getTwoStepVerification();
        })
      ),
    []
  );

  const pendingEmailChange = useUserHasAPendingEmailChange(user);

  const formChanged = !isEqual(original.current, values);
  const formInvalid = () =>
    ["first_name", "last_name", "email"].some(
      (field) => !validWithCurrentUser(field)
    ) || !emailValid();
  const emailValid = () =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.email
    );

  const updateValue = (key, value) =>
    setValues((values) => ({ ...values, [key]: value }));

  useEffect(() => {
    if (save) {
      const { phone_number, ext, time_zone, ...rest } = values;
      if (formInvalid()) {
        setSave();
        return;
      }
      patchUser(user.id, {
        ...rest,
        phone_number: phone_number
          ? ext
            ? `${phone_number?.replace("+1", "")}x${ext}`
            : phone_number?.replace("+1", "")
          : `x${ext}`,
        time_zone: time_zone,
      }).subscribe((user) => {
        setSave();
        successToast(`Your changes have been successfully saved.`);
        auth.updateLoggedInUserObservable(user);
      }, handleError);
    }
  }, [save, values]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'formInvalid' and 'user.id'

  return (
    <div {...scope} className="settings cps-card">
      <div className="cps-subheader cp-p-16">User Settings</div>
      <div className="settings-form">
        <div className="name-row">
          <div
            className={`input-item first-name${
              validWithCurrentUser("first_name") ? "" : " cps-has-error"
            }`}
          >
            <CpInput
              className="cps-form-control"
              onChange={(val) => {
                updateValue("first_name", val);
              }}
              aria-label="first-name"
              value={values.first_name}
              maxLength={50}
              label="First name*"
              error={
                !validWithCurrentUser("first_name") &&
                "The first name is required."
              }
            />
          </div>
          <div
            className={`input-item${
              validWithCurrentUser("last_name") ? "" : " cps-has-error"
            }`}
          >
            <CpInput
              className="cps-form-control"
              onChange={(val) => {
                updateValue("last_name", val);
              }}
              aria-label="last-name"
              value={values.last_name}
              maxLength={50}
              label="Last name*"
              error={
                !validWithCurrentUser("last_name") &&
                "The last name is required."
              }
            />
          </div>
        </div>
        <div className={`input-item${emailValid() ? "" : " cps-has-error"}`}>
          <CpInput
            className="cps-form-control"
            disabled={pendingEmailChange}
            aria-label="email"
            onChange={(val) => {
              updateValue("email", val);
            }}
            value={pendingEmailChange ? user.pendingEmail : values.email}
            maxLength={50}
            label="Email*"
            error={!emailValid() && "Email is required."}
          />
          {pendingEmailChange && (
            <span className="cps-caption">
              Check your email. We sent you a message to confirm your new email
              address.
            </span>
          )}
        </div>
        <CpWell level={2} className="cp-p-8 input-item">
          <i>
            Updating this email will only change your login credentials. To
            change the email your accountant uses to contact you, please contact
            your practitioner.
          </i>
        </CpWell>
        <div className="phone-section input-item">
          <div className="phone">
            <CpInput.PhoneNumber
              className="phone-number"
              value={values.phone_number}
              label="Phone"
              onChange={(val) => updateValue("phone_number", val || "")}
            />
          </div>
          <div className="ext">
            <CpInput
              id="ext"
              className="cps-form-control"
              maxLength={15}
              value={values.ext}
              label="Ext."
              onChange={(e) => updateValue("ext", e)}
            />
          </div>
        </div>
        <div className="cps-form-group input-item">
          <label className="settings-label">Time zone</label>
          <CpTimezone
            onChange={(val) => updateValue("time_zone", val)}
            value={values.time_zone}
            contentWidth="block"
            className="phone-number"
          />
        </div>
        <div className="cp-mb-24">
          <CpButton btnType="secondary" onClick={setPassModalVisible}>
            Reset password
          </CpButton>
        </div>
        <PasswordModal
          show={!!passModalVisible}
          user={user}
          close={() => setPassModalVisible()}
        />
        <div className="cps-form-group cp-mt-16">
          <Suspense fallback={null}>
            <TwoStepVerification />
          </Suspense>
        </div>
        <div className="cp-mt-20">
          <CpButton
            btnType="primary"
            showLoader={save}
            disabled={!original.current || !formChanged || formInvalid()}
            onClick={() => setSave(true)}
          >
            Save changes
          </CpButton>
          <CpButton
            btnType="secondary"
            onClick={() => setValues(getValues(user))}
            disabled={!formChanged}
          >
            Undo
          </CpButton>
        </div>
      </div>
    </div>
  );
}
