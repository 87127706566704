import React, { useState, useEffect, useContext } from "react";
import { get } from "lodash";
import {
  CpButton,
  CpCard,
  CpCheckbox,
  CpIcon,
  CpInput,
  CpWell,
} from "canopy-styleguide!sofe";
import { ConfirmDeleteModal } from "./confirm-delete-modal.component";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import {
  getPaymentMethodName,
  dateFromCardExpiry,
  isExpiredCard,
  paysafeCardTypes,
} from "../billing/create-edit-payment/create-edit-payment.helper";
import { ClientContext } from "src/client-portal.context";
import { BillingContext, PaymentContext } from "../billing/billing-context";

export const ViewPaymentMethod = () => {
  const { tenant, overridePageHeader } = useContext(ClientContext);
  const { hasAdyen } = useContext(BillingContext);
  const { paymentDetails, actions } = useContext(PaymentContext);
  const { makeDefault, paymentMethod } = paymentDetails;

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const phoneOnly = useMediaQuery("--phone-only");

  const cc = paymentMethod.type.includes("CreditCard");

  useEffect(() => {
    overridePageHeader(
      <>
        <div>
          <CpButton
            aria-label="Back"
            btnType="icon-dark"
            icon="caret-large-left"
            onClick={onCancel}
          />
        </div>
        <span className="cps-subheader-sm center-text">
          {cc ? "Credit Card" : "Bank Account"}
        </span>
        <span className="right-text">
          <CpButton
            aria-label="Close"
            btnType="icon-dark"
            icon="close-large"
            onClick={onCancel}
          />
        </span>
      </>
    );
    return () => {
      overridePageHeader();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'cc', 'onCancel', and 'overridePageHeader'

  useEffect(() => {
    actions.authorizeUse(paymentMethod.team_can_use);
  }, [paymentMethod.team_can_use]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'actions'

  const handleNicknameChanged = (value) => {
    const nickname = value.trimLeft();
    actions.updateNickname(nickname);
    actions.setSavedMethodName(nickname);
  };

  const onCancel = () => {
    actions.setShowViewPaymentMethod(false);
  };

  const onClose = () => {
    actions.reset(0, actions.refetchPaymentMethods);
    setLoading(false);
  };

  const onEdit = () => {
    actions.setShowViewPaymentMethod(false);
    actions.setShowCreateEditPaymentMethod(true);
    actions.updatePaymentSession();
  };

  const onConfirmDelete = () => {
    setLoading(true);
    actions.deletePaymentMethod(onClose);
  };

  const expiration =
    cc && paymentMethod.card_expiry
      ? dateFromCardExpiry(paymentMethod.card_expiry)
      : undefined;
  const isExpired = isExpiredCard(paymentMethod);

  return (
    <>
      <CpCard level={phoneOnly ? 0 : 2} className="view-payment-container">
        <CpCard.Header style={{ background: "var(--cp-color-well-l2-bg)" }}>
          <div className="cp-flex-center">
            <CpIcon name={cc ? "billing-credit-card" : "billing-check"} />
            <div className="cp-ml-12">
              <div>{getPaymentMethodName(paymentMethod)}</div>
              {cc && (
                <div style={{ fontStyle: "italic" }}>
                  {isExpired ? (
                    <span className="cps-color-warning">
                      Expired {expiration.toFormat("MM/yyyy")}
                    </span>
                  ) : (
                    <span>Expires {expiration.toFormat("MM/yyyy")}</span>
                  )}
                </div>
              )}
            </div>
          </div>
          {!phoneOnly ? (
            <CpButton
              aria-label="Back"
              btnType="icon"
              icon="caret-large-up"
              onClick={onCancel}
            />
          ) : (
            <CpButton
              aria-label="Delete"
              btnType="icon"
              icon="crud-trash-large"
              onClick={() => setShowConfirmDeleteModal(true)}
            />
          )}
        </CpCard.Header>
        <CpCard.Body>
          <div>
            <div className="flexwrap">
              {cc ? (
                <>
                  <div
                    className="fixed"
                    style={
                      phoneOnly
                        ? { flex: 1, minWidth: "180px" }
                        : { minWidth: "100px" }
                    }
                  >
                    <div className="cps-wt-bold">Credit/debit card</div>
                    <div>
                      {get(paysafeCardTypes, paymentMethod.card_type)} *
                      {paymentMethod.last_four}
                    </div>
                  </div>
                  <div
                    className="fixed"
                    style={
                      phoneOnly
                        ? { flex: 1, minWidth: "180px" }
                        : { minWidth: "100px" }
                    }
                  >
                    <div className="cps-wt-bold">Expiration date</div>
                    <div>{expiration.toFormat("MM/yyyy")}</div>
                  </div>
                  <div
                    className="fixed"
                    style={
                      phoneOnly
                        ? { flex: 1, minWidth: "180px" }
                        : { minWidth: "100px" }
                    }
                  >
                    <div className="cps-wt-bold">Name on card</div>
                    <div>{paymentMethod.card_holder_name}</div>
                  </div>
                </>
              ) : (
                <div
                  className="fixed"
                  style={phoneOnly ? { flex: 1, minWidth: "160px" } : {}}
                >
                  <div className="cps-wt-bold">Bank account</div>
                  <div>
                    *{paymentMethod.last_two || paymentMethod.last_four}
                  </div>
                  <div
                    className="cp-color-app-secondary-text"
                    style={{ fontStyle: "italic" }}
                  >
                    {paymentMethod.account_type}
                  </div>
                </div>
              )}
              <div
                className="fixed"
                style={
                  phoneOnly
                    ? { flex: 1, minWidth: cc ? "180px" : "160px" }
                    : { minWidth: cc ? "100px" : "160px" }
                }
              >
                <div className="cps-wt-bold">Billing address</div>
                <div>
                  {paymentMethod.account_holder_name ||
                    paymentMethod.card_holder_name}
                </div>
                <div>
                  {paymentMethod.billing_address?.street}{" "}
                  {paymentMethod.billing_address?.street2}
                </div>
                <div>
                  {paymentMethod.billing_address?.city},{" "}
                  {paymentMethod.billing_address?.state}{" "}
                  {paymentMethod.billing_address?.zip}
                </div>
              </div>
            </div>
            <div className="cp-mt-16">
              <CpButton
                className="edit-button"
                btnType="secondary"
                onClick={onEdit}
              >
                {cc ? "Replace card info" : "Edit account info"}
              </CpButton>
            </div>
            <div className="cp-mt-16 flexwrap">
              <div style={{ flex: phoneOnly ? "0 0 100%" : 1 }}>
                <CpInput
                  id="nickname"
                  label={`Account nickname${!hasAdyen ? " (optional)" : ""}`}
                  maxLength={30}
                  value={paymentMethod.nickname}
                  onChange={handleNicknameChanged}
                  error={
                    hasAdyen && !paymentMethod.nickname.trim()
                      ? "Nickname is required"
                      : ""
                  }
                />
              </div>
              <div style={{ flex: phoneOnly ? "0 0 100%" : 1 }}>
                <CpCheckbox
                  style={phoneOnly ? {} : { marginTop: "36px" }}
                  disabled={isExpired}
                  onChange={(isChecked) => actions.toggleMakeDefault(isChecked)}
                  checked={makeDefault}
                >
                  Save as default payment method
                </CpCheckbox>
              </div>
            </div>
            <div className="cp-mt-16">
              <CpWell level={3} className="cp-p-16">
                <CpCheckbox
                  onChange={(isChecked) => actions.authorizeUse(isChecked)}
                  checked={paymentMethod.authorizedUse}
                >
                  <div className="cp-ml-12">
                    I authorize {tenant.company_name} to charge my{" "}
                    {cc ? "credit/debit card " : "bank account "}
                    above for agreed-upon transactions. I understand that my
                    information will be saved to file for future transactions on
                    my account.
                  </div>
                </CpCheckbox>
              </CpWell>
            </div>
          </div>
        </CpCard.Body>
        {!phoneOnly && (
          <CpCard.Footer>
            <div className="cp-flex-spread">
              <div>
                <CpButton
                  btnType="primary"
                  className="cp-mr-8"
                  disabled={hasAdyen && !paymentMethod.nickname}
                  onClick={() => {
                    setLoading(true);
                    actions.savePaymentMethod(onClose, () => setLoading(false));
                  }}
                >
                  Save changes
                </CpButton>
                <CpButton btnType="tertiary" onClick={onCancel}>
                  Cancel
                </CpButton>
              </div>
              <CpButton
                aria-label="Delete"
                btnType="icon"
                icon="crud-trash-large"
                onClick={() => setShowConfirmDeleteModal(true)}
              />
            </div>
          </CpCard.Footer>
        )}
      </CpCard>
      {phoneOnly && (
        <div className="save-changes-button-wrapper">
          <CpButton
            btnType="primary"
            className="save-changes-button"
            showLoader={loading}
            disabled={hasAdyen && !paymentMethod.nickname}
            onClick={() => {
              setLoading(true);
              actions.savePaymentMethod(onClose, () => setLoading(false));
            }}
          >
            Save changes
          </CpButton>
        </div>
      )}
      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        paymentType={cc ? "card" : "ach"}
        canDelete={
          paymentMethod.can_delete !== false &&
          paymentMethod.payments?.length === 0
        }
        onCancel={() => setShowConfirmDeleteModal(false)}
        onConfirm={onConfirmDelete}
        loading={loading}
      />
    </>
  );
};
