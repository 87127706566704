import React, { Suspense, useMemo } from "react";
import { useCss } from "kremling";
import { CpArea, CpTooltip } from "canopy-styleguide!sofe";
import { DateTime } from "luxon";
import css from "src/todo/todo-card.krem.css";

const DocIcon = React.lazy(() =>
  import("docs-ui!sofe").then((mod) => mod.loadDocIcon())
);

export function RecentFile({ item, onClick, seenByClient }) {
  const scope = useCss(css);
  const createdAt = useMemo(() => {
    return DateTime.fromISO(item.created_at).toLocaleString(DateTime.DATE_MED);
  }, [item]);

  return (
    <CpArea
      {...scope}
      focusInset
      className="task-card cps-card__height-2"
      onClick={onClick}
    >
      <div className="cps-margin-right-16">
        <Suspense fallback={null}>
          <DocIcon doc={item} color="var(--cp-color-app-icon)" />
        </Suspense>
      </div>
      <div className="task-card-body">
        <CpTooltip text={item?.name}>
          <div className="cps-body cps-wt-semibold name">{item?.name}</div>
        </CpTooltip>
        <div>{createdAt}</div>
      </div>
      {seenByClient && <div className="new-file-badge">New</div>}
    </CpArea>
  );
}
