import React, { useEffect, useState } from "react";
import { CpButton, CpModal, CpIcon, CpInput } from "canopy-styleguide!sofe";
import { useCss, k } from "kremling";
import { patchUser } from "./settings.resource";
import { handleError } from "src/handle-error";
import { successToast } from "toast-service!sofe";
import { useMediaQuery } from "@hooks/use-media-query.hook";

export default function PasswordModal(props) {
  const [charLimit, setCharLimit] = useState(false);
  const [visible, setVisible] = useState();
  const [save, setSave] = useState();
  const phoneOnly = useMediaQuery("--phone-only");
  const { show, close, user } = props;
  const [password, setPassword] = useState("");

  useEffect(() => {
    setCharLimit(password.length > 9);
  }, [password]);

  useEffect(() => {
    if (save) {
      patchUser(user.id, {
        password: document.getElementById("password").value,
      }).subscribe(() => {
        successToast("Password Updated");
        setSave(false);
        close();
      }, handleError);
    }
  }, [save]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'close' and 'user.id'

  return (
    <CpModal show={show} onClose={close} width={560}>
      <CpModal.Header title="Reset Password" border={false} />
      <CpModal.Body>
        <div
          {...useCss(css)}
          className={`client body ${phoneOnly ? "phone" : "full-width"}`}
        >
          <div className="left">
            <CpInput
              id="password"
              type={visible ? "text" : "password"}
              className="cps-form-control"
              aria-label="New password"
              maxLength={50}
              onChange={(e) => setPassword(e)}
              label="New password"
              value={password}
            />
            <CpIcon
              aria-label={visible ? "Hide password" : "Show password"}
              onClick={() => setVisible((a) => !a)}
              className="visibilityIcon"
              name={visible ? "misc-open-eye" : "misc-closed-eye"}
              style={{ ...(phoneOnly && { top: 36 }) }}
            />
          </div>
          <div
            className={`requirements ${phoneOnly ? "cp-ml-12" : "cp-ml-24"}`}
          >
            Password requirements:
            <ul className="cp-pl-12">
              <li className={charLimit ? "valid" : "invalid"}>
                At least 10 characters long
              </li>
            </ul>
          </div>
        </div>
      </CpModal.Body>
      <CpModal.Footer className="client">
        <CpButton
          btnType="primary"
          showLoader={save}
          onClick={() => setSave(true)}
          disabled={!charLimit}
        >
          Save password
        </CpButton>
        <CpButton btnType="flat" onClick={close} className="cp-ml-12">
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}

const css = k`
  .body {
    display: flex;
  }
  .phone {
    flex-direction: column;
  }
  .full-width {
    min-height: 13.8rem;
    align-items: center;
  }
  .valid {
    color: var(--cp-color-checkbox-selected-bg);
    display: block;
  }
  .valid::before {
    content: '✓';
    margin-right: .8rem;
    margin-left: -1.2rem;
  }
  .invalid {
    color: var(--cp-color-app-inactive-text);
  }
  .visibilityIcon {
    position: absolute;
    right: .6rem;
    top: 3.6rem;
    cursor: pointer;
  }
  .left {
    position: relative;
    width: 254px;
  }
  .requirements {
    padding-top: 2.4rem;
  }
`;
