import { fetcher } from "fetcher!sofe";
import { useQuery, genQueryKey } from "src/react-query";
import { QUESTIONNAIRE_TYPES } from "./questionnaire-types.constant";

export const useGetQuestionnaire = ({
  id,
  api = QUESTIONNAIRE_TYPES.QUESTIONNAIRE.api,
}) => {
  const query = useQuery({
    queryKey: genQueryKey(api, { id }),
    queryFn: () => fetcher(`/wg/${api}/${id}`),
    staleTime: 60 * 1000, // 1 minute
    enabled: !!id,
  });

  return query;
};

export const useGetClientRequest = (id) => {
  const query = useQuery({
    queryKey: genQueryKey("tasks", { id }),
    queryFn: () =>
      fetcher(`/api/client-requests/${id}`).then((res) => res.client_request),
    staleTime: 60 * 1000, // 1 minute
    enabled: !!id,
  });

  return query;
};
