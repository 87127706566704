import { useQuery, genQueryKey } from "src/react-query";
import { getRelatedFiles } from "src/todo/client-request/comments/comment.resource";

export function useRequestCommentAttachments({ clientRequestId, commentIds }) {
  const query = useQuery({
    querykey: genQueryKey("client-request-comment-attachments", {
      clientRequestId,
      commentIds,
    }),
    queryFn: () =>
      getRelatedFiles({
        type: "comment",
        ids: commentIds,
        parentId: clientRequestId,
      }),
    staleTime: 60 * 1000, // 1 minute
    enabled: !!clientRequestId && !!commentIds?.length,
  });

  return {
    query,
    filesByComment: query.data?.comment,
  };
}
