import { useEffect, useState } from "react";
import { getRequestStatus } from "../todo/todo.helper";
import { todo$ } from "@hooks/use-refetch-todos.hook";

export default function useTodoCount() {
  const [todoCount, setTodoCount] = useState();

  useEffect(() => {
    const disposable = todo$.subscribe((todos) => {
      const { client_requests, surveys, task_client_requests } = todos;
      setTodoCount(
        client_requests.filter(
          (cr) => getRequestStatus(cr.status) === "incomplete"
        ).length +
          surveys.length + // if they're returned, they're in complete
          task_client_requests.filter(
            (task) => getRequestStatus(task.status_id) === "incomplete"
          ).length
      );
    });
    return () => disposable.unsubscribe();
  }, [todoCount]);

  return todoCount;
}
