export const QUESTIONNAIRE_TYPES = {
  QUESTIONNAIRE: {
    api: "questionnaires",
    button: "View Questionnaire",
    icon: "communication-paper-pen",
    sentence: "questionnaire",
    title: "Questionnaire",
    type: "questionnaire",
    url: "questionnaire",
  },
  DOCUMENT_CHECKLIST: {
    api: "questionnaires", //TODO:document-checklists",
    button: "View checklist",
    icon: "files-filled",
    sentence: "document checklist",
    title: "Document Checklist",
    type: "document_checklist",
    url: "document-checklist",
  },
};
