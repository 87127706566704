import React, { useContext, useLayoutEffect } from "react";
import { CpLoader } from "canopy-styleguide!sofe";
import SelectInvoices from "./select-invoices.component";
import AdditionalPaymentCard from "./additional-payment.component";
import PaymentSummaryCard from "./payment-summary-card.component";
import PaymentDetails from "./payment-details.component";
import ConfirmDetails from "./confirm-details.component";
import PaymentReceipt from "./payment-receipt.component";
import PaymentError from "./payment-error.component";
import { BillingContext, PaymentContext } from "../billing-context";
import { ClientContext } from "src/client-portal.context";
import { paymentSteps } from "./create-edit-payment.helper";

export default function PaymentSteps({ step, clientId, container }) {
  const { hasAdyen, surchargeFeeText } = useContext(BillingContext);
  const { paymentDetails, status, actions } = useContext(PaymentContext);
  const { tenant } = useContext(ClientContext);
  const { paymentTotal, paymentMethod, surchargeFee, shouldSurcharge } =
    paymentDetails;

  useLayoutEffect(() => {
    if (container?.current) {
      const parent = findScrolledParent(container.current);
      parent?.scrollTo(0, 0);
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'container' and 'findScrolledParent'

  const findScrolledParent = (node) => {
    if (node === null) return null;
    if (node.scrollTop > 0) return node;
    return findScrolledParent(node.parentElement);
  };

  return (
    <>
      {step === paymentSteps.SelectInvoices && (
        <>
          <SelectInvoices clientId={clientId} />
          <AdditionalPaymentCard />
          <PaymentSummaryCard total={paymentTotal} step={step} />
        </>
      )}
      {step === paymentSteps.PaymentDetails && (
        <PaymentSummaryCard
          total={paymentTotal}
          step={step}
          paymentType={paymentMethod?.type}
          surchargeFee={surchargeFee}
          setSurchargeFee={actions.setSurchargeFee}
          shouldSurcharge={shouldSurcharge}
        />
      )}
      <PaymentDetails
        actions={actions}
        hasAdyen={hasAdyen}
        paymentDetails={paymentDetails}
        show={step === paymentSteps.PaymentDetails}
        status={status}
        tenant={tenant}
      />
      {step === paymentSteps.ConfirmDetails && (
        <ConfirmDetails
          paymentDetails={paymentDetails}
          surchargeFeeText={surchargeFeeText}
          tenant={tenant}
        />
      )}
      {step === paymentSteps.Processing && <CpLoader />}
      {step === paymentSteps.Receipt && <PaymentReceipt />}
      {step === paymentSteps.Error && (
        <PaymentError error={status.paymentError} />
      )}
    </>
  );
}
