import React from "react";
import { CpEmptyState } from "canopy-styleguide!sofe";
import { useCss, a } from "kremling";
import css from "./user-message-card.krem.css";

export default function UserMessageCard(props) {
  const { children, noLeftNav, addTopMenuPadding, ...rest } = props;
  return (
    <div {...useCss(css)}>
      <div
        className={a("container")
          .m("navLeft", !noLeftNav)
          .m("topMenuOffset", addTopMenuPadding)}
      >
        <div className="cps-card cps-padding-32">
          <CpEmptyState {...rest} />
          {children}
        </div>
      </div>
    </div>
  );
}
