import React, { useContext } from "react";
import { Link } from "@reach/router";
import { useCss } from "kremling";
import {
  CpButton,
  CpCard,
  CpIcon,
  CpLoader,
  CpTooltip,
} from "canopy-styleguide!sofe";
import CreateEditPaymentModal from "../create-edit-payment/create-edit-payment-modal.component";
import { BillingContext } from "../billing-context";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { formatNumber } from "../billing-helpers";
import { useBalance } from "../billing-hooks";

import css from "./balance-card.krem.css";

export default function BalanceCard({ clientId, loading, extended }) {
  const phoneOnly = useMediaQuery("--phone-only");
  const {
    hasAdyen,
    hasBillingPermissions,
    amexEnabled,
    showCpBilling,
    showPaymentFlow,
    setShowPaymentFlow,
    surchargeEnabled,
    setShowSurchargeDisclosure,
    surchargeFeeText,
  } = useContext(BillingContext);

  const { balance } = useBalance(
    clientId,
    showCpBilling,
    hasBillingPermissions
  );

  const scope = useCss(css);

  return extended ? (
    <>
      <div {...scope} className="balance-card-extended-wrapper">
        <Link
          to={`/m/clients/${clientId}/billing/invoices`}
          className="no-decor"
        >
          <CpCard level={2}>
            <div className="balance-card-extended cp-color-app-secondary-text">
              <div
                className="cp-flex-center"
                style={{
                  justifyContent: phoneOnly ? "center" : "space-between",
                }}
              >
                <div className="balance-text-wrapper">
                  {loading ? (
                    <CpLoader />
                  ) : (
                    <>
                      <div className="balance-text-extended">
                        <span className="currency-symbol">$</span>
                        {formatNumber(balance, false, 2)}
                      </div>
                      <div>Outstanding balance</div>
                    </>
                  )}
                </div>
                {!phoneOnly && (
                  <div style={{ textAlign: "right" }}>
                    <div>Credit/Debit Accepted</div>
                    <div>
                      <CpIcon name="billing-visa" />
                      <CpIcon name="billing-mastercard" />
                      <CpIcon name="billing-discover" />
                      {(amexEnabled || hasAdyen) && (
                        <CpIcon name="billing-amex" />
                      )}
                    </div>
                    {surchargeEnabled && (
                      <div style={{ fontStyle: "italic" }}>
                        {surchargeFeeText} fee applies{" "}
                        <CpTooltip
                          text={
                            <div className="cp-text-center">
                              This amount is not greater than the amount paid by
                              the merchant for processing the transaction.
                            </div>
                          }
                        >
                          <CpButton
                            small
                            btnType="icon"
                            icon="information-circle-open-small"
                          />
                        </CpTooltip>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="cp-mt-16">
                <div className="cp-flex-spread-center">
                  <div style={{ flex: 1 }}>
                    {phoneOnly ? (
                      <Link
                        to={`/m/clients/${clientId}/billing/payment/create`}
                        className="no-decor"
                      >
                        <CpButton btnType="primary" block>
                          Make a payment
                        </CpButton>
                      </Link>
                    ) : (
                      <CpButton
                        btnType="primary"
                        block
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPaymentFlow(true);
                        }}
                      >
                        Make a payment
                      </CpButton>
                    )}
                  </div>
                  {!phoneOnly && (
                    <div style={{ flex: 1, textAlign: "right" }}>
                      <Link
                        to={`/m/clients/${clientId}/billing/invoices`}
                        className="no-decor"
                      >
                        <CpButton btnType="flat">View invoices</CpButton>
                      </Link>
                    </div>
                  )}
                </div>
                {phoneOnly && surchargeEnabled && (
                  <div
                    className="cp-flex-center cp-mt-8"
                    style={{ justifyContent: "center" }}
                  >
                    <div>
                      <CpIcon name="billing-visa" />
                      <CpIcon name="billing-mastercard" />
                      <CpIcon name="billing-discover" />
                      {(amexEnabled || hasAdyen) && (
                        <CpIcon name="billing-amex" />
                      )}
                    </div>
                    {surchargeEnabled && (
                      <div className="cp-flex-center cp-caption cp-ml-8">
                        {surchargeFeeText} fee
                        <CpTooltip
                          text={
                            <div className="cp-text-center">
                              This amount is not greater than the amount paid by
                              the merchant for processing the transaction.
                            </div>
                          }
                        >
                          <CpButton
                            onClick={(e) => {
                              e.preventDefault();
                              setShowSurchargeDisclosure(true);
                            }}
                            small
                            btnType="icon"
                            icon="information-circle-open-small"
                          />
                        </CpTooltip>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </CpCard>
        </Link>
      </div>
      {!phoneOnly && (
        <CreateEditPaymentModal
          show={showPaymentFlow}
          onClose={() => setShowPaymentFlow(false)}
          clientId={clientId}
        />
      )}
    </>
  ) : (
    <div {...scope} className="balance-card-wrapper">
      <CpCard level={2}>
        <div className="balance-card flex-center flex-column">
          {loading ? (
            <CpLoader />
          ) : (
            <div className="balance-text">
              <span className="currency-symbol">$</span>
              {formatNumber(balance, false, 2)}
            </div>
          )}
          <div className="cp-color-app-secondary-text">Outstanding balance</div>
          {phoneOnly && surchargeEnabled && (
            <div
              className="cp-flex-center cp-mt-8"
              style={{ justifyContent: "center" }}
            >
              <div>
                <CpIcon name="billing-visa" />
                <CpIcon name="billing-mastercard" />
                <CpIcon name="billing-discover" />
                {(amexEnabled || hasAdyen) && <CpIcon name="billing-amex" />}
              </div>
              {surchargeEnabled && (
                <div className="cp-flex-center cp-caption cp-ml-8">
                  {surchargeFeeText} fee
                  <CpTooltip
                    text={
                      <div className="cp-text-center">
                        This amount is not greater than the amount paid by the
                        merchant for processing the transaction.
                      </div>
                    }
                  >
                    <CpButton
                      onClick={() => setShowSurchargeDisclosure(true)}
                      small
                      btnType="icon"
                      icon="information-circle-open-small"
                    />
                  </CpTooltip>
                </div>
              )}
            </div>
          )}
        </div>
      </CpCard>
    </div>
  );
}
