import "./set-public-path.js";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import { canopyPaymentsQueries } from "billing-ui/externals!sofe";
import { Root } from "./root.component.js";
import { Scoped } from "kremling";
import { queryClient } from "src/react-query";

// @ts-expect-error no types for Scoped
Scoped.defaultNamespace = "client-portal";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  childAppName: "client-portal",
});

export const bootstrap = [
  setupPaysafe,
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById("client-app");
  if (!el) {
    el = document.createElement("div");
    el.id = "client-app";
    document.body.appendChild(el);
  }
  return el;
}

function setupPaysafe() {
  return new Promise<void>((resolve, reject) => {
    const setup = async () => {
      if (window.paysafe) {
        resolve();
        return;
      }

      try {
        const data = await queryClient.fetchQuery(
          canopyPaymentsQueries.paymentsStatus({})
        );

        if (data?.is_active) {
          const el = document.createElement("script");
          el.setAttribute(
            "src",
            `https://hosted.paysafe.com/js/v1/latest/paysafe.min.js`
          );
          el.setAttribute("type", "text/javascript");
          const head = document.getElementsByTagName("head")[0];
          head.appendChild(el);
        }

        resolve();
      } catch (e) {
        reject(e);
      }
    };

    setup();
  });
}

export const loadRenameFileModal = () =>
  import(
    /* webpackChunkName: "rename-file-modal" */ "src/files/rename-file-modal.component.js"
  ).then((mod) => mod);

export const loadDeleteFileConfirmModal = () =>
  import(
    /* webpackChunkName: "delete-file-confirm-modal" */ "src/files/delete-file-confirmation.component.js"
  ).then((mod) => mod);

export const loadItemComponent = () =>
  import(
    /* webpackChunkName: "item-component" */ "src/files/item.component.js"
  ).then((mod) => mod);

export const getPaymentDetails = () =>
  import(
    /* webpackChunkName: "payment-details" */ "src/billing/create-edit-payment/payment-details.component.js"
  ).then((mod) => mod);
