import React, { useContext, useRef } from "react";
import { useCss } from "kremling";
import { CpButton, CpLoader, CpModal } from "canopy-styleguide!sofe";
import PaymentSteps from "./payment-steps.component";
import { BillingContext, PaymentContext } from "../billing-context";
import {
  paymentSteps,
  paymentStepConfig,
  paymentViewMode,
} from "./create-edit-payment.helper";
import { usePayments } from "./payment.hooks";

import css from "./create-edit-payment.krem.css";

export default function CreateEditPaymentModal(props) {
  const { show, onClose, clientId, invoiceId, paymentId } = props;
  const scope = useCss(css);
  const { amexEnabled, hasAdyen, surchargeEnabled } =
    useContext(BillingContext);
  const modalBody = useRef();
  const { step, paymentDetails, status, actions, paysafeInstance } =
    usePayments({
      clientId,
      invoiceId,
      paymentId,
      amexEnabled,
      hasAdyen,
      surchargeEnabled,
      paymentViewMode: paymentViewMode.Payments,
      onComplete: onClose,
      show,
    });

  return (
    <PaymentContext.Provider
      value={{ paymentDetails, status, actions, paysafeInstance }}
    >
      <CpModal
        width={640}
        show={show}
        onClose={() => {
          if (step !== paymentSteps.Processing) {
            onClose();
            actions.reset(500);
          }
        }}
      >
        <CpModal.Header
          title={
            paymentStepConfig[step].title(
              !!paymentDetails.paymentId,
              paymentDetails.isFuturePayment
            ) || status.paymentError.title
          }
        />
        <CpModal.Body>
          <div {...scope} ref={modalBody}>
            {status.loadingInvoices ? (
              <CpLoader />
            ) : (
              <PaymentSteps
                step={step}
                clientId={clientId}
                container={modalBody}
              />
            )}
          </div>
        </CpModal.Body>
        <CpModal.Footer>
          <div className="cp-flex-spread">
            <div>
              {paymentStepConfig[step].prevButton && (
                <CpButton
                  btnType="secondary"
                  className="cp-mr-8"
                  onClick={actions.prevStep}
                >
                  {paymentStepConfig[step].prevButton()}
                </CpButton>
              )}
              {paymentStepConfig[step].nextButton && (
                <CpButton
                  btnType="primary"
                  className="cp-mr-8"
                  disabled={
                    !paymentStepConfig[step].validate({
                      paymentDetails,
                      invalidFields: status.invalidFields,
                      validCardTypes: status.validCardTypes,
                      paysafeInstance,
                      hasAdyen,
                    })
                  }
                  onClick={actions.nextStep}
                >
                  {paymentStepConfig[step].nextButton(
                    paymentDetails.isFuturePayment ||
                      paymentDetails.isScheduledPayment
                  )}
                </CpButton>
              )}
              {step !== paymentSteps.Processing &&
                step !== paymentSteps.Receipt && (
                  <CpButton
                    btnType="flat"
                    onClick={() => {
                      onClose();
                      actions.reset(500);
                    }}
                  >
                    Cancel
                  </CpButton>
                )}
            </div>
            <div>
              {paymentId &&
                step !== paymentSteps.Receipt &&
                step !== paymentSteps.Processing && (
                  <CpButton
                    btnType="flat"
                    onClick={() => {
                      actions.deletePayment();
                      onClose();
                      actions.reset(500);
                    }}
                  >
                    Cancel scheduled payment
                  </CpButton>
                )}
            </div>
          </div>
        </CpModal.Footer>
      </CpModal>
    </PaymentContext.Provider>
  );
}
