import { useEffect, useState } from "react";
import { forkJoin, ReplaySubject } from "rxjs";
import { fetchAsObservable } from "fetcher!sofe";
import { pluck, map } from "rxjs/operators";
import { handleError } from "src/handle-error";

const todosSubject = new ReplaySubject();

export const todo$ = todosSubject.asObservable();

export const useRefetchTodos = () => {
  const [client, setClient] = useState();
  const [refetch, setRefetch] = useState();

  useEffect(() => {
    if (client && refetch) {
      const obs = forkJoin(
        fetchAsObservable(`/api/clients/${client.id}/requests`),
        fetchAsObservable(`/wg/clients/${client.id}/surveys`).pipe(
          pluck("surveys")
        )
      )
        .pipe(
          map(([{ client_requests, task_client_requests }, surveys]) => ({
            task_client_requests,
            client_requests,
            surveys,
          }))
        )
        .subscribe(
          (res) => setRefetch() || todosSubject.next(res),
          handleError
        );
      return () => obs.unsubscribe();
    }
  }, [client, refetch]);
  return (client) => setClient(client) || setRefetch(true);
};
