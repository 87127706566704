import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { isEmpty, noop } from "lodash";
import { useCss } from "kremling";
import {
  CpButton,
  CpCard,
  CpIcon,
  CpLoader,
  CpRadio,
  CprDatepicker,
} from "canopy-styleguide!sofe";
import { CreateEditPaymentMethod } from "./create-edit-payment-method.component";
import {
  getPaymentMethodName,
  isExpiredCard,
} from "./create-edit-payment.helper";

import css from "./create-edit-payment.krem.css";

const PaymentDetails = ({
  actions,
  hasAdyen,
  paymentDetails,
  show,
  status,
  tenant,
}) => {
  const scope = useCss(css);
  const [invalidPaymentDateMessage, setInvalidPaymentDateMessage] =
    useState("");

  useEffect(() => {
    if (
      !status.loadingPaymentMethods &&
      paymentDetails.savedPaymentMethods?.length === 0
    ) {
      showCreatePaymentMethodForm();
    }
  }, [paymentDetails.savedPaymentMethods]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'showCreatePaymentMethodForm' and 'status.loadingPaymentMethods'

  const validatePaymentDate = (date) => {
    let message = "";
    const paymentDate = DateTime.fromISO(date?.toISOString()).startOf("day");
    if (!date) {
      message = "Payment date is required";
      actions.updatePaymentDate(DateTime.local().startOf("day"));
    } else if (DateTime.local().startOf("day") - paymentDate > 0) {
      message = "Past payments are not supported";
      actions.updatePaymentDate(DateTime.local().startOf("day"));
    } else if (paymentDetails.paymentDate !== paymentDate) {
      actions.updatePaymentDate(paymentDate);
    }
    setInvalidPaymentDateMessage(message);
  };

  const showCreatePaymentMethodForm = () => {
    actions.updatePaymentMethod({ type: "newCreditCard" });
    actions.setShowCreateEditPaymentMethod(true);
  };

  const showEditPaymentMethodForm = (method) => {
    actions.updatePaymentMethod(method);
    actions.setShowCreateEditPaymentMethod(true);
    if (hasAdyen) {
      actions.setSavedMethod(true);
      actions.setSavedMethodName(method.nickname);
    }
  };

  return (
    <div
      className={status?.isEngagement ? "" : "payment-details"}
      style={{ display: show ? "" : "none" }}
      {...scope}
    >
      {!status?.isEngagement && (
        <>
          <div className="cp-mt-16">
            <label id="paymentDate">
              Payment date<span className="cps-color-primary">*</span>
            </label>
          </div>
          <div>
            <CprDatepicker
              id="paymentDate"
              name="paymentDate"
              date={paymentDetails.paymentDate.toJSDate()}
              events={{
                datechange: (date) => {
                  validatePaymentDate(date.detail);
                },
              }}
              orientation="bottom right"
            />
            {invalidPaymentDateMessage && (
              <span className="cps-has-error">{invalidPaymentDateMessage}</span>
            )}
          </div>
        </>
      )}
      {status.loadingPaymentMethods ||
      (status.showCreateEditPaymentMethod &&
        hasAdyen &&
        isEmpty(paymentDetails.adyenSessionDetails)) ? (
        <CpLoader />
      ) : status.showCreateEditPaymentMethod ? (
        <CreateEditPaymentMethod
          actions={actions}
          hasAdyen={hasAdyen}
          isEngagement={status?.isEngagement}
          paymentDetails={paymentDetails}
          show={show}
          tenant={tenant}
        />
      ) : (
        <>
          {!status?.isEngagement && (
            <div className="cp-mt-16">Select a payment method</div>
          )}

          {paymentDetails.savedPaymentMethods?.map((method) => {
            const isExpired = isExpiredCard(method);
            const validCardType = method.cardType
              ? status.validCardTypes.includes(method.cardType)
              : true;
            return (
              <React.Fragment key={method.id}>
                <CpCard
                  level={2}
                  className={`payment-method-card cp-flex-spread ${
                    isExpired ? "expired" : ""
                  } ${
                    paymentDetails.paymentMethod.id === method.id
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => {
                    !isExpired &&
                      validCardType &&
                      actions.updatePaymentMethod(method);
                  }}
                >
                  <div className="cp-flex-center">
                    {!isExpired && validCardType && (
                      <CpRadio
                        name={`payment-method-${method.id}`}
                        value={paymentDetails.paymentMethod.id}
                        onChange={noop}
                      >
                        <CpRadio.Item id={method.id} />
                      </CpRadio>
                    )}
                    {validCardType && method.is_preferred && (
                      <CpIcon name="shape-star-filled" className="cp-mr-4" />
                    )}
                    {getPaymentMethodName(method, isExpired, validCardType)}
                  </div>
                  {isExpired && (
                    <CpButton
                      aria-label="Edit"
                      btnType="icon"
                      icon="crud-pencil"
                      onClick={() => showEditPaymentMethodForm(method)}
                    />
                  )}
                </CpCard>
              </React.Fragment>
            );
          })}
        </>
      )}

      <div className="cp-flex" style={{ justifyContent: "center" }}>
        {paymentDetails.savedPaymentMethods?.length > 0 &&
          (status.showCreateEditPaymentMethod ? (
            <CpButton
              btnType="flat"
              className="cp-mt-16"
              onClick={() => actions.resetPaymentMethod(false)}
            >
              Use saved payment method
            </CpButton>
          ) : (
            <CpButton
              btnType="flat"
              className="cp-mt-16"
              onClick={showCreatePaymentMethodForm}
            >
              Use another payment method
            </CpButton>
          ))}
      </div>
    </div>
  );
};

export default PaymentDetails;
